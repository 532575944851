#home {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.navigation {
    display: flex;
    height: 180px;
    justify-content: space-between;
    align-items: center;
}

.upper_content {
    display: flex;
    flex-direction: column;
    width: 1150px;
}

.upper_content > h1 {
    font-size: 36px;
    font-weight: bold;
    color: #1C1C1C;
    margin: 0 0 16px 0;
    padding: 0;
}



/*styling*/
#home > div.scroll_area > a:nth-child(1) > div {
    grid-area: 1 / 1 / 2 / 2;
    background-image: url("../../images/el1.jpeg");
}
#home > div.scroll_area > a:nth-child(2) > div {
    grid-area: 1 / 2 / 2 / 3;
    background-image: url("../../images/el2.jpeg");
}
#home > div.scroll_area > a:nth-child(3) > div {
    grid-area: 1 / 3 / 2 / 4;
    background-image: url("../../images/el3.jpeg");
}
#home > div.scroll_area > a:nth-child(4) > div {
    grid-area: 2 / 3 / 3 / 4;
    background-image: url("../../images/el4.jpeg");
}
#home > div.scroll_area > a:nth-child(5) > div {
    grid-area: 2 / 2 / 3 / 3;
    background-image: url("../../images/el5.jpeg");
}
#home > div.scroll_area > a:nth-child(6) > div {
    grid-area: 2 / 1 / 3 / 2;
    background-image: url("../../images/el6.jpeg");
}
#home > div.scroll_area > a:nth-child(7) > div {
    grid-area: 3 / 1 / 4 / 2;
    background-image: url("../../images/el7.jpeg");
}
#home > div.scroll_area > a:nth-child(8) > div {
    grid-area: 3 / 2 / 4 / 3;
    background-image: url("../../images/el8.jpeg");
}
#home > div.scroll_area > a:nth-child(9) > div {
    grid-area: 3 / 3 / 4 / 4;
    background-image: url("../../images/el10.jpeg");
}
#home > div.scroll_area > a:nth-child(10) > div {
    grid-area: 4 / 1 / 5 / 2;
    background-image: url("../../images/el11.jpeg");
}
#home > div.scroll_area > a:nth-child(11) > div {
    grid-area: 4 / 2 / 5 / 3;
    background-image: url("../../images/el12.jpeg");
}
#home > div.scroll_area > a:nth-child(12) > div {
    grid-area: 4 / 3 / 5 / 4;
    background-image: url("../../images/el13.jpeg");
}
#home > div.scroll_area > a:nth-child(13) > div {
    grid-area: 5 / 1 / 6 / 2;
    background-image: url("../../images/el14.jpeg");
}
#home > .scroll_area > a:nth-child(14) > div {
    grid-area: 5 / 2 / 6 / 3;
    background-image: url("../../images/el15.jpeg");
}

/*scrolling*/
.scroll_area {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}

.last_added {
    display: flex;
    width: 1150px;
}

.items {
    max-width: 1170px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
}

.sub {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.sub_title {
    font-weight: bold;
    color: #1C1C1C;
    margin: 0 0 16px 0;
    padding: 0;
    margin-top: 0;
    font-size: 36px;
    line-height: 42px;
    margin-bottom: 28px;
}

.sub_desc {
    font-family: "Istok Web", "helvetica", sans-serif;
    color: #4e4e4e;
    font-size: 16px;
    line-height: 26px;
}

.sub_button {
    gap: 10px;
    margin-top: 22px;
    background: linear-gradient(-280deg, #7C10FF 0%, #A95CFF 100%);
    color: #fff;
    border: 2px solid transparent;
    border-left: 2px solid #7C10FF;
    border-right: 2px solid #A95CFF;
    transition: background 0.3s, color 0.3s;
    font-size: 16px;
    font-weight: 600;
    height: 50px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    width: 255px;
}

.tgLogo__2mLz6 {
    vertical-align: middle;
}

.tgLogoColor__Bb6uz {
    transition: fill 0.3s;
}

.tgLogoColor__hEFIc {
    fill: #fff;
}

.gradient__8mdzV {
    color: #fff;
}

.sub_button:hover {
    background-image: none;
    border: 2px solid #7200FF;
    background-color: #fff;
    color: #7200FF;
}

.sub_button:hover .tgLogoColor__Bb6uz {
    fill: #5800FF;
}

.footer {
    min-width: 1170px;
    width: 100%;
    background: #fff;
    box-shadow: 0 1px 5px 0 rgba(183, 155, 218, 0.19);
    display: flex;
    height: 100px;
    bottom: 0;
    align-items: center;
    align-items: center;
    justify-content: center;
}

.footer > div {
    width: 1170px;
}

.footer > div > .footer_content {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.footer_content > div {
    display: flex;
    align-items: center;
    gap: 20px;
}

.footer_content > div > p{
    color: gray;
    opacity: 0.7;
}

.footer_href {
    color: black;
    text-decoration: none;
}

.footer_href:hover {
    text-decoration: underline;
}

.footer_content > div:nth-child(2) > a {
    text-decoration: underline;
    color: #7200FF;
    font-weight: bold;
}

.sub_desc {text-align: center}