.header {
    display: flex;
    justify-content: center;
    width: 100%;
    box-shadow: 0 1px 5px 0 rgba(183, 155, 218, 0.19);
}

header {
    display: flex;
    justify-content: space-between;

    align-items: center;
    padding: 23px 1%;
    min-width: 1170px;
}

header > div {
    display: flex;
    align-items: center;
    gap: 40px;
}

#home > header > div:nth-child(1) > img {
    margin-right: 4vw;
}

.login {
    margin-left: 4vw;
}

.catalog,
.search {
    font-size: 12px;
    line-height: 16px;
    color: #4e4e4e;
    font-weight: bold;
    text-decoration: none;
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: none;
    vertical-align: top;
    letter-spacing: 0.02em;
    font-family: "Istok Web", "helvetica", sans-serif;
}

.create_ad {
    font-family: "Istok Web", "helvetica", sans-serif;
    box-sizing: border-box;
    border: 2px solid #7200FF;
    line-height: 18px;
    font-size: 16px;
    font-weight: bold;
    padding: 15px 27px 13px;
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    height: 50px;
    border-radius: 50px;
    background-color: transparent;
    color: #7200FF;
}

.create_ad:hover {
    color: #fff;
    background: linear-gradient(-280deg, #7C10FF 0%, #A95CFF 100%);
    border: 0;
    padding: 17px 29px 15px;
    transition: background 0.3s, color 0.3s;
}

.login {
    background: transparent;
    border: none;
    padding: 0;
    outline: none;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: #4e4e4e;
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
    flex-shrink: 0;
    text-align: right;
    font-family: "Istok Web", "helvetica", sans-serif;
}

.catalog:after {
    display: inline-block;
    width: 0;
    height: 0;
    content: "";
    border-top: 4px solid;
    border-right: 3px solid transparent;
    border-bottom: 0;
    border-left: 3px solid transparent;
    margin-left: 4px;
    vertical-align: middle;
    margin-bottom: -1px;
    transition: 0.3s -webkit-transform;
    transition: 0.3s transform;
    transition: 0.3s transform, 0.3s -webkit-transform;
}

.catalog.active:after {
    transform: rotate(180deg);
}

.catalog:hover {
    text-decoration: underline;
}

.language {
    display: flex;
    background-color: transparent;
    border: 0;
}

.language:after {
    position: absolute;
    margin-left: 25px;
    margin-top: 10px;
    width: 0;
    height: 0;
    content: "";
    border-top: 4px solid;
    border-right: 3px solid transparent;
    border-bottom: 0;
    border-left: 3px solid transparent;
    vertical-align: middle;
    margin-bottom: -1px;
}

.language.active, .language {
    position: relative;
}

.language.active {
    background-color: #A95CFF;
    padding-right: 15px;
    padding-top: 5px;
    border-radius: 5px 5px 0px 5px;
    position: absolute;
    margin-left: -75px;
    margin-top: -5px;
}

.language.active:after {
    border-top: 4px solid white;
    border-right: 3px solid transparent;
    border-bottom: 0;
    border-left: 3px solid transparent;
}