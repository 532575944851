.overlay_tg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 101;
    background-color: rgb(255, 255, 255, 0.5);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup_tg {
    background: #fff;
    position: relative;
    padding: 68px 34px 30px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 20px 0 #E1E8EE;
    transition: background-color 0.3s;
    z-index: 103;
    min-width: 372px;
    min-height: 332px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.steps > div:nth-child(1) {
    display: flex;
    gap: 20px;
    justify-content: center;
}

.step1, .step2 {
    justify-content: flex-end;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    color: #1C1C1C;
    font-family: "Istok Web", "helvetica", sans-serif;
    font-size: 24px;
    font-weight: bold;
    transition: all 0.3s;
}

.svg_animated {
    margin-left: -80px;
    position: absolute;
    transition: 1s all;
}

purple {
    color: #7200FF;
}

.login_content {
    color: #4e4e4e;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 24px;
    margin-top: 15px;
}

.login_content > a {
    text-decoration: none;
}

.written {
    background-image: linear-gradient(-280deg, #7C10FF 0%, #A95CFF 100%);
    color: #fff;
    border: 2px solid transparent;
    border-left: 2px solid #7C10FF;
    border-right: 2px solid #A95CFF;
    transition: background 0.3s, color 0.3s;
    padding-left: 42px;
    padding-right: 42px;
    line-height: 18px;
    font-size: 16px;
    font-weight: bold;
    padding: 15px 27px 13px;
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    height: 50px;
    font-family: "Istok Web", "helvetica", sans-serif;
    box-sizing: border-box;
    border-radius: 8px;
}

.written:hover {
    background-image: none;
    border: 2px solid #7200FF;
    background-color: #fff;
    color: #7200FF;
}

.login_code {
    margin-top: 68px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

.input_code {
    color: #1C1C1C;
    font-size: 16px;
    border-radius: 2px 0 0 2px;
    border: 1px solid #E1E8EE;
    outline: none;
    display: inline-block;
    border-radius: 8px;
    width: 100%;
    box-sizing: border-box;
    line-height: 21px;
    margin-right: -2px;
    padding: 12px 60px 16px 19px;
    vertical-align: top;
    height: 50px;
}