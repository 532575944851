.overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 101;
    background-color: rgb(255,255,255,0.5);
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    box-sizing: border-box;
    min-height: 200px;
    padding: 68px 34px 30px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 20px 0 #E1E8EE;
    color: #fff;
    background: linear-gradient(71.2deg, #7C10FF 1.17%, #A95CFF 96.49%);
    transition: background-color 0.3s;
}
.close {
    background-color: transparent;
    border: 0;
}

.close img {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
}

i.icon-close__1jCY9.close__icon__3k4IZ:before {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    line-height: inherit;
    text-decoration: inherit;
    content: '\E81C';
    font-family: "Montserrat";
    font-style: normal;
}

.whoops {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 12px;
    text-align: center;
}

.description {
    font-family: "Istok Web", "helvetica", sans-serif;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 24px;
    text-align: center;
}

.auth {
    font-family: "Istok Web", "helvetica", sans-serif;
    box-sizing: border-box;
    line-height: 18px;
    font-size: 16px;
    font-weight: bold;
    padding: 15px 27px 13px;
    cursor: pointer;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    height: 50px;
    background: #fff;
    color: #8016FF;
    border-color: #fff;
    border-radius: 5px;
    border: 1px solid white;
    transition: 0.3s all;
}

.about a {
    color: #fff;
    text-decoration: underline;
    font-size: 12px;
    font-family: "Istok Web", "helvetica", sans-serif;
}

.about {
    margin-top: 46px;
    text-align: center;
}

.auth:hover {
    color: #fff;
    background: transparent;
    border: 1px solid white;
}