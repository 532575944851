.element {
    position: relative;
    text-decoration: none;
    color: black;
    width: 370px;
    height: 256px;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    transition: background-image 0.5s ease;
}

.content {
    width: 370px;
    height: 200px;
    border-radius: 10px;
    transition: all 0.3s;
}

.type {
    font-weight: 600;
    position: absolute;
    color: white;
    left: 10px; /* Додано left */
    text-decoration: none;
    padding: 2px 10px;
    border-radius: 15px;
    transition: all 0.3s;
    z-index: 100;
}

.price {
    padding-top: 60px;
    padding-left: 10px;
    color: white;
    margin: 0;
    font-size: 32px;
    transition: all 0.3s;
    max-width: 50%;
}

.info {
    display: block;
    max-width: 350px;
    color: white;
    padding-right: 10px;
    word-wrap: break-word;
    transition: all 0.3s;
    padding-left: 10px;
    padding-right: 10px;
}

.element:hover {
    transition: all 0.3s;
    box-shadow: 0 4px 20px 0 rgba(183, 155, 218, 0.4);
}

.heading {
    display: flex;
    margin-left: 10px;
    font-size: 18px;
    margin-top: 18px;
    transition: all 0.3s;
}


.element_content {
    position: relative;
    width: 370px;
    height: 200px;
    border-radius: 15px;
    overflow: hidden;
    z-index: -3;
    background-size: cover;
    background-position: center;
}

.element_content::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(18deg, rgba(124, 16, 255, 0.8), rgba(169, 92, 255, 0.8));
    opacity: 0;
    transition: all 0.3s;
    z-index: -2;
    background-size: cover;
}

.element:hover .element_content::after {
    opacity: 1;
}

.element:hover .type {
    color: #7200FF;
    background-color: white !important;
    z-index: 100;
}

