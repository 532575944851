.input1, .input2 {
    -webkit-appearance: none;
    box-sizing: border-box;
    vertical-align: top;
    padding: 0 20px 0 0;
    font-family: "Istok Web", "helvetica", sans-serif;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
    font-weight: bold;
    color: #1C1C1C;
    border: none;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 110px;
    margin: 0;
    border: 0;
}

.input1[type="text"], .input2[type="text"] {
    padding-block: 1px;
    padding-inline: 2px;
}

.input1:focus,
.input2:focus {
    border: none;
    outline: none;
}
