.li {
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    transition: 0.25s all;
}

.li a {
    transition: 0.25s all;
    font-size: 12px;
    font-family: "Istok Web", "helvetica", sans-serif;
    text-decoration: none;
}

.li img {
    transition: 0.25s all;
    background: transparent;
    width: 80px;
    height: 80px;
    border-radius: 50%;
}

.li:hover a {
    color: #7200FF;
}

.li:hover img {
    background: #fff;
    box-shadow: 0 1px 5px #E1E8EE;
}

