.item {
    width: 214px;
    height: 269px;
    display: flex;
    flex-direction: column;
    position: relative;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px 0 rgba(183, 155, 218, 0.25);
    overflow: hidden;
}

.item_image {
    width: 216px;
    height: 164px;
    display: flex;
    border-radius: 25px;
    position: relative;
    justify-content: center;
    align-items: center;
}

.item_image img {
    width: 216px;
    height: 164px;
    object-fit: cover;
    transition: 0.5s all;
}
.item_image:hover img {
    scale: 1.05
}

.item_section {
    font-weight: 600;
    position: absolute;
    color: white;
    left: 10px;
    text-decoration: none;
    padding: 2px 10px;
    border-radius: 15px;
    transition: all 0.3s;
    z-index: 100;
}

div.items > div > div.item_content > p {
    margin-top: 20px;
    color: #1C1C1C;
    font-size: 14px;
    line-height: 16px;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 32px;
    -webkit-line-clamp: 2;
    transition: color .4s ease-in-out;
    font-weight: 700;
    position: relative;
}

div.items > div > div.item_content > div > p {
    position: relative;
    color: #4e4e4e;
    font-size: 14px;
    line-height: 16px;
    box-sizing: border-box;
    font-family: "Roboto", "helvetica", sans-serif;
    font-weight: 300;
}

div.items > div > div.item_content > div {
    position: absolute;
    margin-top: 50px
}

.item_content {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
}

gray {
    color: #CECECE;
}

.like {
    position: absolute;
    top: -15px;
    right: 10px;
    z-index: 3;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    width: 30px;
    height: 30px;
    background-image: linear-gradient(-280deg, #7C10FF 0%, #A95CFF 100%);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon__1zZ4v {
    width: 18px;
    margin-bottom: -1px;
    z-index: 100;
}

.heartStyles__1XLYP {
    fill: transparent;
    stroke: #fff;
    transition: all 0.3s;
}

.like:hover .heartStyles__1XLYP {
    fill: #fff;
}

.sub {
    width: 1050px;
    height: 225px;
    background-image: url('../../images/contact_background.png');
    background-repeat: no-repeat;
    background-position: center bottom;
    box-shadow: 0 1px 5px 0 rgba(183, 155, 218, 0.19);
    border-radius: 8px;
    margin: 40px 0;
    padding: 39px 50px;
}