.open_lang {
    position: absolute;
    top: 57px;
    margin-left: -127px;
    z-index: 99;
    background-image: linear-gradient(-280deg, #7C10FF 0%, #A95CFF 100%);
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    border-radius: 5px;
}

.open_lang > a {
    display: flex;
    align-items: center;
    gap: 6px;
    text-decoration: none;
    color: white;
    font-weight: 600;
}