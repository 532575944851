/* modals */
header > div:nth-child(1) {
    position: relative;
}

.open_catalog {
    width: 894px;
    height: 353px;
    padding: 33px 10px 14px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 1fr);
    position: absolute;
    top: 40px;
    left: calc(55%);
    background: white;
    z-index: 999;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.19);
    grid-auto-rows: 110px;
}

.open_catalog > .catalog_li {
    box-sizing: border-box;
    padding-left: 10px;
    padding-right: 10px;
    flex: 0 0 20%;
    max-height: 127px;
}

div.open_catalog > div.catalog_li > h3 {
    margin: 0;
    color: #1C1C1C;
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    transition: all 0.3s;
}

div.open_catalog > div.catalog_li > h3 > a {
    text-decoration: none;
    color: black;
    transition: all 0.3s;
}

.catalog_li > ul {
    margin: 0;
    padding-left: 18px;
}

.catalog_li > ul > li > a {
    color: #4e4e4e;
    text-decoration: none;
    font-size: 12px;
    transition: all 0.3s;
}

/* hover */
#home > div.header > header > div > div > div > ul > li > a:hover,
#home > div.header > header > div > div > div > h3 > a:hover {
    color: #7200FF;
}